import { type Expression, Parser, type ParserOptions } from 'expr-eval'
import type { MaybeRef } from 'vue'
import {
  extractVariables,
  resolveRelativePath,
  resolveVariable,
} from '@manager/utils'

type DefaultFactory<T> = () => T | null | undefined

export type EvaluateOptions<D> = {
  expression: Expression | null
  values?: Record<string, any>
  default?: DefaultFactory<D> | D | null | undefined
}

export interface UseEvaluationReturn {
  resolveExpression(
    expression: string | null | undefined,
    relativePath?: string,
  ): string | undefined
  parse(
    expression: string | null | undefined,
    options?: ParserOptions,
  ): Expression | null
  evaluate<T = any, D = T>(options: EvaluateOptions<D>): T
}

export const useEvaluation = (): UseEvaluationReturn => {
  const resolveExpression = (
    expression: string | null | undefined,
    relativePath?: string,
  ): string | undefined => {
    let _expression = expression ?? undefined

    if (_expression) {
      // Each variable is an absolute path
      const absoluteVariables = extractVariables(_expression)

      // Resolve relative paths
      for (const absoluteVariable of absoluteVariables) {
        let relativeVariable = absoluteVariable

        if (relativePath) {
          try {
            relativeVariable = resolveRelativePath(
              absoluteVariable,
              relativePath,
            )
          } catch (error) {
            console.error(error)
          }
        }

        // Replace the absolute path with relative path (calculated)
        _expression = _expression.replaceAll(
          absoluteVariable,
          resolveVariable(relativeVariable, 'calculated'),
        )
      }
    }

    return _expression
  }
  const parse = (
    expression: string | null | undefined,
    options?: ParserOptions,
  ): Expression | null => {
    const parser = new Parser(options)
    try {
      // @ts-expect-error - If the expression fails, we catch and return null
      return parser.parse(expression)
    } catch (e) {
      console.error(e)
      return null
    }
  }
  const evaluate = <T = any, D = T>({
    expression,
    values = {},
    default: defaultValue,
  }: {
    expression: Expression | null
    values?: Record<string, any>
    default?: D | DefaultFactory<D> | null | undefined
  }) => {
    try {
      return (expression?.evaluate(values) ?? defaultValue) as T
    } catch (e) {
      return defaultValue as T
    }
  }

  return {
    resolveExpression,
    parse,
    evaluate,
  }
}
