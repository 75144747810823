export function resolveRelativePath(globalPath: string, localPath: string) {
  const globalPathComponents = globalPath.split('.')
  const localPathComponents = localPath.split('.')

  const result = globalPathComponents.map((globalComponent, i) => {
    if (globalComponent.includes('[]') && localPathComponents[i]) {
      return replaceBracketsWithIndex(globalComponent, localPathComponents[i])
    } else {
      return globalComponent
    }
  })

  return result.join('.')
}

function replaceBracketsWithIndex(
  globalComponent: string,
  localComponent: string,
) {
  const globalComponentWithoutBrackets = globalComponent.replace('[]', '')
  const localComponentWithoutIndex = localComponent.split('[')[0]

  if (globalComponentWithoutBrackets !== localComponentWithoutIndex) {
    throw new Error(
      `Path mismatch: ${globalComponentWithoutBrackets} does not match ${localComponentWithoutIndex}`,
    )
  }

  const match = localComponent.match(/\[(\d+)]/)
  if (!match || match[1] === undefined) {
    throw new Error(
      `Index not found in path for component: ${globalComponentWithoutBrackets}`,
    )
  }

  return globalComponent.replace('[]', `[${match[1]}]`)
}
